import React from 'react'
import Fade from 'react-reveal/Fade'
import { Link } from 'gatsby'

import SEO from '../components/seo'

const NotFoundPage = () => (
  <>
    <SEO title="404: Not found" />
    <section className='services-block approach error-404'>
      <div className='services-block__inner'>
        <Fade bottom distance={'40px'}>
          <h1>404 Error</h1>
          <div className='services-block__content approach__content'>
          	You've encountered a page that doesn't exist.
          </div>
          <Link to='/' className='btn'>Back home</Link>
        </Fade>
      </div>
    </section>
  </>
)

export default NotFoundPage
